* {
    transition: all 0.5s ease;
}

.sub-text {
    font-size: 25px;
}

div.clear {
    background: none;
    border: 0;
    clear: both;
    display: block;
    float: none;
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.contractLink {color: whitesmoke;
    line-height: 100%;
}

.buttonMessage{    font-size: 70%;
    font-family: sans-serif;
    color: #f0ec4e;}
.buttonMessage a {color: #ffffff; text-decoration: underline;}

body {
    background: black;
    color: black;
    margin: 0;
    padding: 0;
    font-family: 'Titillium Web', sans-serif !important;
}

a {
    color: white;
    text-decoration: none;
}

.white {
    color: white;
}

.noCollectionSpacer { height:30px; }

.smaller {
    font-size: 80%;
}

.fixcaps {
    text-transform: initial !important;
}

div.MiddleSection {
    color: black;
    display: grid;
    @media(max-width: 765px) {
        margin-bottom: 1rem;
    }
}

div.GridItem {
    align-self: center;
    @media(max-width: 765px) {
        margin-bottom: 1rem;
    }
}

div.Welcome {
    text-transform: uppercase;
    font-size: 3vw;
    @media(max-width: 800px) {
        font-size: 4vw;
    }
}

div.MaxPerWallet {
    color: rgb(255 255 255 / 60%);
    font-size: 1.1rem;
    @media (max-width: 764px) {
        font-size: 4vw;
    }
}

div.PriceWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

div.PriceText {
    font-size: 2vw;
    @media(max-width: 764px) {
        font-size: 4vw;
    }
}

div.AmountWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

button.CounterBtnWrap {
    border: 0;
    background: transparent;
    color: white;
    font-size: 4vw;
    cursor: pointer;
    @media (max-width: 764px) {
        font-size: 10vw;
    }
}

div.CounterInput {
    background: transparent;
    border: solid white 2px;
    text-align: center;
    width: 15%;
    margin-right: 2rem;
    margin-left: 2rem;
    font-size: 1rem;
    padding: 1rem;
    @media( max-width: 764px) {
        width: 30%;
    }
}

input.slider {}

div.MintBtn {
    margin: 2rem 0;
    background: #d94745 !important;
    color: #0F0F0F !important;
    cursor: pointer;
    text-transform: uppercase;
    justify-self: self-start;
    border: 0 !important;
    font-size: 1.5vw;
    transition: all .2s;
}

.MintBtn:hover {
    color: white !important;
    background: black !important;
    transition: all .2s;
}

div.SoldFont {
    font-size: 1.4rem;
    color: white;
    @media (max-width: 765px) {
        font-size: 2rem;
    }
}

img.CollectionImage {
    width: 65%;
    border-radius: 1rem;
}

div.LoadingFont {
    font-size: 1.5vw;
    color: white;
}

div.CountDownTimer {}

#pageLogo img {
    width: 100%;
}

#pageLogo {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 15px;
    left: 9%;
}



#saleStatus {
    font-size: 150%;
    color: #fff;
    text-decoration: overline;
    text-decoration-color: #00ffa7;
    text-decoration-thickness: from-font;
    text-decoration-style: wavy;
}

#theStatus {
    padding-left: 15px;
    color: white;
}

.pageHighlightColor {
    color: #f0ec4e;
}

.pageHighlightColor2 {
    color: #6a624a;
}

#branding {
    padding-top: 20px;
}

#branding img {
    border: 2px solid black;
    -webkit-box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
    -moz-box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
    box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
}

#navButtons {
    margin-top: 20px;
}

img.navIcons {
    position: relative;
    top: 3px;
    left: -7px;
    width: 20px;
    height: 20px;
}

.logo-wallet {
    width: 90px;
    height: 90px;
}
.walletName{

    font-family: 'Titillium Web', sans-serif;
    font-weight: 700;
    font-size: 200%;
    vertical-align: top;

}

#DAppArea button { margin-top:15px;  }
#totalPrice { margin-top:15px; margin-bottom:15px;  }
#prepare button, #DAppArea button { background: #320264;    color: white;    border: initial;    padding: 10px 20px 10px 20px;    border-radius: 9px;    text-transform: uppercase;    font-family: 'Titillium Web', sans-serif;   font-size: 110%; }
#prepare button:hover, #DAppArea button:hover { background: #000;  }

div.ReactModal__Content.ReactModal__Content--after-open {    background: #5d42f3 !important;    text-align: center; border: 5px solid black !important;  border-radius: 9px !important; padding-top: 10px !important; }
div.ReactModal__Content.ReactModal__Content--after-open h2 {  color: #f0ec4e !important;     font-size: 160%;    letter-spacing: 2px;    margin-bottom: 0;    margin-top: 0; }
div.ReactModal__Content.ReactModal__Content--after-open button {    background: white;    border: initial;    padding: 5px;    border-radius: 5px;     margin-bottom: 15px; }
div.ReactModal__Content.ReactModal__Content--after-open .providerChoices {     width: 100%;  margin-bottom: 10px; background: #fff;  border: 3px solid black;    border-radius: 9px;    
    margin-top: 10px; }

    .topBanner img { max-width: 400px;  margin-top: 10px; }
    
    .topBanner {
        background: black;
        padding: 20px;
        padding-bottom: 0;
        text-align: center;
    }

.topBannerWrapper { max-width: 1200px;    margin-left: auto;    margin-right: auto; }

.topBannerLeft { float: left;
    width: 20%; }

.topBannerRight { width: 80%;
    float: right;
    margin: 0;
    padding: 0;
    text-align: right;     padding-top: 2%; }

    .topBannerRight a { color: white;
        padding-left: 30px;
        font-size: 100%;}
    .topBannerRight a:hover { color:#f0ec4e; }

.topBannerButton {
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    box-shadow: 3px 3px 11px rgb(0 0 0 / 40%);
    -webkit-box-shadow: 3px 3px 11px rgb(0 0 0 / 40%);
    -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
    background: black;
    margin: 20px 20px 20px 20px;
}

.topBannerButton:hover {
    background: #d54aff;
}

#DAppArea h2 {
    color: #f0ec4e;
    font-size: 300%;
    margin-top: 0;
    font-weight: 600;line-height: 125%;
}

#DAppArea h3 {
    color: #6a624a;
    font-style: italic;
    font-size: 150%;
    font-weight: 100;
}

#walletButton {}

.white { color: white; }

.qty { color: #8ab7ff; }

button:hover .qty { color: white; }

.welcome {
    text-align: center;
    font-size: 100%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 30px;
    MARGIN-TOP: 0;
    letter-spacing: 1px;
}

span.copyrightNotice {
    font-size: 65%;
}

div.mintBoxWrapper {
    background: #374e4a;
    width: 90%;
    display: flex;
    overflow: auto;
    margin: 15px auto 20px auto;
    max-width: 1200px;
    border: 4px solid #f0ec4e;
    border-radius: 9px;
}

div.mintBoxLeft {width: 50%;
    padding: 20px;
    float: left;
    text-align: center;
    background-image: url(0_apgif.gif);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #0000008f;
    background-position-y: center;
}


div.mintBoxRight {
    width: 100%;
    padding: 20px;
    float: left;
    text-align: center;
    padding: 2%;
}

.mobileOnly {
    display: none;
}

#currentMintCount {}

/* YOUR COLLECTION */

.collectionWrapper {
    padding: 1% 2% 3% 2%;
    background: #1c1c1c;
    text-align: center;
    background: black;
    background-image: url(https://yearofthewoman.io/wp-content/uploads/2022/02/4907157-scaled.jpg?id=251) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

input.pageButton span {}

.collectionWrapper h2 {
    font-size: 225%;
}

.collectionRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    margin-top: 2rem;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
}

#renderCollection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    margin-top: 2rem;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
}

.collectionItem {}

.collectionImage {
    width: 75%;
    border-radius: 1rem;
    vertical-align: middle;
    border: 4px solid #f0ec4e;
}

.collectionTokenTitle {
    margin-top: 1rem;
    color: #f0ec4e;
    font-size: 125%;
}

.header {
    width: 100%;
    background: #000000;
    padding: 0;
    text-align: center;
    color: black;
    padding-bottom: 9px;
    padding-top: 15px;
}

.footer {
    width: 100%;
    background: #000000;
    padding: 0;
    text-align: center;
    color: black;
    padding-bottom: 15px;
    padding-top: 7px;

    bottom : 0px;  
    height : 40px;  
    margin-top : 40px;  
    text-align: center;  
    vertical-align: middle; 
    position:fixed;
    width:100%;

}

a.pageButton {
    background: #f0ec4e;
    color: black;
    padding: 4px 2% 4px 2%;
    margin: 0 1% 0 1%;
    line-height: 250%;
    border-radius: 20px;
    box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
}

a.pageButton:hover {
    background: #320264;
    color: whitesmoke;
}

.bannerButton {
    padding: 9px 13% 8px 13%;
    line-height: 250%;
    border-radius: 5px;
    box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
}

.bannerButton:hover {
    background: #d54aff;
}

#HeaderButtons {
    margin-top: 20px;
    position: absolute;
    top: 1%;
    right: 6%;
}

.header img {
    position: relative;
    top: 3px;
    left: -4px;
    width: 20px;
}

.footer img {
    position: relative;
    top: 3px;
    left: -4px;
    width: 20px;
}

.credits {
    background: #778384;
    text-align: center;
    font-size: 80%;
    letter-spacing: 1px;
    color: white;
}

.credits a {
    color: black;
}

.larger {
    font-size: 200%;
    font-weight: 100;
    letter-spacing: initial;
}

input[type=range] {
    width: 30%;
    margin: 8px 0;
    background-color: transparent;
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    background: #ffffff;
    border: 1px solid #dbd58f;
    border-radius: 25px;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
    margin-top: -9px;
    width: 33px;
    height: 22px;
    background: #dbd58f;
    border: 4px solid black;
    border-radius: 9px;
    cursor: pointer;
    -webkit-appearance: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ffffff;
}

input[type=range]::-moz-range-track {
    background: #ffffff;
    border: 1px solid #dbd58f;
    border-radius: 25px;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-moz-range-thumb {
    width: 33px;
    height: 22px;
    background: gold;
    border: 4px solid black;
    border-radius: 9px;
    cursor: pointer;
}

input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 12.1px 0;
    color: transparent;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-ms-fill-lower {
    background: #ebebeb;
    border: 1px solid #dbd58f;
    border-radius: 50px;
}

input[type=range]::-ms-fill-upper {
    background: #ffffff;
    border: 1px solid #dbd58f;
    border-radius: 50px;
}

input[type=range]::-ms-thumb {
    width: 33px;
    height: 22px;
    background: gold;
    border: 4px solid black;
    border-radius: 9px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}

input[type=range]:focus::-ms-fill-lower {
    background: #ffffff;
}

input[type=range]:focus::-ms-fill-upper {
    background: #ffffff;
}

@media (min-width: 1400px) {
    #renderCollection {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1130px) {

    .topBanner img { width: 175%; }
    .topBannerRight a { padding-left: 20px; }

}

@media (max-width: 800px) {
    div.mintBoxLeft {        width: 0%;        padding: 0;     }
    div.mintBoxRight {         width: 100%;        padding: 20px;     }
    .mobileOnly {         display: block;        margin: 10px auto 20px auto;        max-width: 350px;     }
    #renderCollection {         grid-template-columns: 1fr 1fr;    }
    #amountSold {}
    .topBanner {         background: #745feb;       min-height: 80px;  }
    .footer { display:none; }
    .topBannerRight, .topBannerLeft { width: 100%; float: initial; text-align: center; }
    .topBanner img { width: 90%; }

}

@media (max-width: 500px) { 

   .topBanner { min-height: 50px; }
   .header img, .footer img { display:none; }

}
